<template>

    <div class="container mt-3">

        <h2>
            <i class="bi bi-person-lines-fill"></i>
            {{ $t('titles.customerList') }}
        </h2>

        <div class="row my-3">

            <div class="col">
                <button
                    v-if="masterUser()"
                    type="button"
                    tag="button"
                    class="btn btn-outline-primary btn-sm mx-3"
                    @click.prevent="createResource()"
                  >
                    <i class="bi bi-pencil-square"></i>
                    {{ $t('form.actions.newCustomer') }}
                </button>
            </div>
        </div>

        <div class="row my-3">

            <div class="col-9 ms-3">

                <div class="input-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        v-model="state.form.search"
                        @keyup.enter="searchCustomers()"
                    >
                    <button
                        class="btn btn-outline-secondary"
                        type="button"
                        @click.prevent="searchCustomers()"
                    >
                        <i class="bi bi-search"></i>
                        {{ $t('form.actions.search') }}
                    </button>


                </div>
            </div>

            <div class="col-2 me-3">

                <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click.prevent="getAllCustomers()"
                >
                    <i class="bi bi-arrow-repeat"></i>
                </button>

            </div>
        </div>

        <div class="row">
            <div class="col align-self-start">
                <div class="ms-3 my-3">
                    <div>
                        <label class="form-check-label" for="filters">{{ $t('form.actions.filtersOn') }} :</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model.number="useCustomers.state.checkedCustomers" class="form-check-input" type="checkbox" :value="parseInt(1)">
                        <label class="form-check-label" for="active">{{ $t('models.customer.active') }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model.number="useCustomers.state.checkedCustomers" class="form-check-input" type="checkbox" :value="parseInt(0)">
                        <label class="form-check-label" for="inactive">{{ $t('models.customer.inactive') }}</label>
                    </div>
                </div>
            </div>

            <div class="col align-self-center">
                <ul class="justify-content-end nav">

                    <NavItem
                        :quantity="10"
                        :selected="useCustomers.state.pagination.meta.perPage"
                        @setNbPerPageForPagination="(n) => setNbPerPageForPagination(n)"
                    />

                    <span class="align-self-center mx-1"> | </span>

                    <NavItem
                        :quantity="20"
                        :selected="useCustomers.state.pagination.meta.perPage"
                        @setNbPerPageForPagination="(n) => setNbPerPageForPagination(n)"
                    />

                    <span class="align-self-center mx-1"> | </span>

                    <NavItem
                        :quantity="useCustomers.state.pagination.meta.total"
                        :selected="useCustomers.state.pagination.meta.perPage"
                        @setNbPerPageForPagination="(n) => setNbPerPageForPagination(n)"
                    />

                </ul>
            </div>
        </div>


        <div
            v-show="useCustomers.state.form.loading"
            class="spinner"
        >
            Loading customers...
        </div>


        <div class="d-flex justify-content-center">
            <div v-show="useCustomers.state.form.loading" class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>


        <!-- Display server error messages, from server validation -->
        <!-- Diplay alert message received from backend -->
        <MyModal
            v-bind:idProps="state.modal.alert"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ useModals.state.modal.header }}
            </template>

            <template v-slot:body>

                <AltertMessage
                    v-bind:data="useCustomers.state.form.response"
                ></AltertMessage>

            </template>

        </MyModal>


        <div
            v-if="!useCustomers.state.form.loading"
        >

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.customer.id')"
                                    :sortArgs="useCustomers.tableSort"
                                    :headerName="'id'"
                                    @sort-event="useCustomers.sortCustomers('id', 'number')"
                                />
                            </th>

                            <!-- <th scope="col">profession</th> -->
                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.customer.civility')"
                                    :sortArgs="useCustomers.tableSort"
                                    :headerName="'civility'"
                                    @sort-event="useCustomers.sortCustomers('civility', 'string')"
                                />
                            </th>

                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.customer.lastname')"
                                    :sortArgs="useCustomers.tableSort"
                                    :headerName="'lastname'"
                                    @sort-event="useCustomers.sortCustomers('lastname', 'string')"
                                />
                            </th>

                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.customer.firstname')"
                                    :sortArgs="useCustomers.tableSort"
                                    :headerName="'firstname'"
                                    @sort-event="useCustomers.sortCustomers('firstname', 'string')"
                                />
                            </th>

                            <!-- <th scope="col">street</th> -->
                            <!-- <th scope="col">number</th> -->

                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.customer.postcode')"
                                    :sortArgs="useCustomers.tableSort"
                                    :headerName="'postcode'"
                                    @sort-event="useCustomers.sortCustomers('postcode', 'number')"
                                />
                            </th>

                            <th scope="col">
                                <TableHeaderItem
                                    :titleName="$t('models.customer.city')"
                                    :sortArgs="useCustomers.tableSort"
                                    :headerName="'city'"
                                    @sort-event="useCustomers.sortCustomers('city', 'string')"
                                />
                            </th>

                            <!-- <th scope="col">phone</th> -->
                            <!-- <th scope="col">mobile</th> -->
                            <th scope="col">{{ $t('models.customer.email') }}</th>
                            <th scope="col">{{ $t('models.customer.show') }}</th>
                            <template v-if="masterUser()">
                                <th scope="col">{{ $t('models.customer.active') }}</th>
                                <th scope="col">{{ $t('models.customer.edit') }}</th>
                                <th scope="col">{{ $t('models.customer.delete') }}</th>
                            </template>

                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="customer in useCustomers.state.selectedCustomers"
                            v-bind:key="customer"
                        >
                            <td>{{ customer.id }}</td>
                            <!-- <td>{{ customer.profession }}</td> -->
                            <td>{{ customer.civility }}</td>
                            <td>
                                <a href="#" @click.prevent="showResource(customer)">
                                    {{ customer.lastname }}
                                </a>
                            </td>

                            <td>{{ customer.firstname }}</td>
                            <!-- <td>{{ customer.street }}</td> -->
                            <!-- <td>{{ customer.number }}</td> -->
                            <td>{{ customer.postcode }}</td>
                            <td>{{ customer.city }}</td>
                            <!-- <td>{{ customer.phone }}</td> -->
                            <!-- <td>{{ customer.mobile }}</td> -->
                            <td>
                                <a v-bind:href="'mailto:' + customer.email">
                                    {{ customer.email }}
                                </a>
                            </td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"

                                    @click.prevent="showResource(customer)"
                                >
                                    <i class="bi bi-eye"></i>
                                </a>
                            </td>
                            <template v-if="masterUser()">
                                <td>
                                    <a
                                        v-if="customer.active == 1"
                                        class="icon-edit"
                                        href="#"
                                        @click.prevent="toggleActive(customer)"
                                    >
                                        <i class="bi bi-check-square"></i>
                                    </a>

                                    <a
                                        v-else
                                        class="icon-edit"
                                        href="#"
                                        @click.prevent="toggleActive(customer)"
                                    >
                                        <i class="bi bi-x-square"></i>
                                    </a>
                                </td>


                                <td>
                                    <a
                                        class="icon-edit"
                                        href="#"

                                        @click.prevent="editResource(customer)"
                                    >
                                        <i class="bi bi-pencil-fill"></i>
                                    </a>
                                </td>
                                <td>
                                    <a
                                        class="icon-edit"
                                        href="#"
                                        @click.prevent="deleteResource(customer)"
                                    >
                                        <i class="bi bi-trash"></i>
                                    </a>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row my-3 py-3">
            <nav aria-label="Page navigation example" class="my-3">
                <ul class="pagination justify-content-center mb-3">
                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            aria-label="Previous"
                            @click.prevent="getPreviousCustomersPagination()"
                        >
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>

                    <li
                        v-for="page in useCustomers.state.pagination.meta.lastPage"
                        v-bind:key="page"
                        class="page-item"
                        v-bind:class="page === useCustomers.state.pagination.meta.curentPage ? 'active' : ''"
                        @click.prevent="getPageCustomersPagination(page)"
                    >
                        <a class="page-link" href="#">{{ page }}</a>
                    </li>

                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            aria-label="Next"
                            @click.prevent="getNextCustomersPagination()"
                        >
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>



        <MyModal
            v-bind:idProps="state.modal.show"
            @click.prevent="useModals.state.modal.componentShow.toggle()"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ useModals.state.modal.header }}
            </template>

            <template v-slot:body>

                <table class="table">
                    <thead>
                        <th scope="col">Index</th>
                        <th scope="col">Item</th>
                        <th scope="col">typeOf</th>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in state.customerToShow"
                            v-bind:key="item"
                        >
                            <td>{{ index }}</td>
                            <td>{{ item }}</td>
                            <td>{{ typeof(item) }}</td>
                        </tr>
                    </tbody>



                </table>

                <div class="fs-6 fw-light text-decoration-underline mb-3">{{ $t('models.customer.information') }}</div>
                <div v-html="state.customerInfo" class="ql-snow ql-editor"></div>

            </template>


        </MyModal>




        <MyModal
            v-bind:idProps="state.modal.edit"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ useModals.state.modal.header }}
            </template>

            <template v-slot:body>

                <!-- Display server error message from database -->
                <ExceptionMessage
                    v-bind:exception="useCustomers.useErrors.state.exception"
                    v-bind:exceptionMessage="useCustomers.useErrors.state.exceptionMessage"
                ></ExceptionMessage>

                <div class="form-floating mb-3">
                    <select
                        class="form-select rounded-4"
                        id="civility"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.civility) ? 'is-invalid' : 'is-valid'"
                        v-model="useCustomers.state.form.body.civility"
                        @keyup.enter="saveResource()"
                    >
                        <option value="">{{ $t('models.customer.validations.civility') }}</option>
                        <option v-for="option in useCustomers.state.form.options.civilities" :value="option.value" v-bind:key="option.value">
                            {{ option.text }}
                        </option>

                    </select>
                    <label for="civility">{{ $t('models.customer.civility') }}</label>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="lastname"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.lastname) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="lastname"
                        v-model="useCustomers.state.form.body.lastname"
                    >
                    <label for="lastname">{{ $t('models.customer.lastname') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.lastname)" class="text-info text-sm">
                        {{ $t('models.customer.validations.lastname') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.lastname"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="firstname"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.firstname) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="firstname"
                        v-model="useCustomers.state.form.body.firstname"
                    >
                    <label for="firstname">{{ $t('models.customer.firstname') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.firstname)" class="text-info text-sm">
                        {{ $t('models.customer.validations.firstname') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.firstname"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="street"
                        @keyup.enter="saveResource()"
                        placeholder="street"
                        v-model="useCustomers.state.form.body.street"
                    >
                    <label for="street">{{ $t('models.customer.street') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.street)" class="text-info text-sm">
                        {{ $t('models.customer.validations.street') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.street"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="number"
                        @keyup.enter="saveResource()"
                        placeholder="number"
                        v-model="useCustomers.state.form.body.number"
                    >
                    <label for="number">{{ $t('models.customer.number') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.number)" class="text-info text-sm">
                        {{ $t('models.customer.validations.number') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.number"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="number"
                        class="form-control rounded-4"
                        id="postcode"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.postcode) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="postcode"
                        v-model="useCustomers.state.form.body.postcode"
                    >
                    <label for="postcode">{{ $t('models.customer.postcode') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.postcode)" class="text-info text-sm">
                        {{ $t('models.customer.validations.postcode') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.postcode"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="city"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.city) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="city"
                        v-model="useCustomers.state.form.body.city"
                    >
                    <label for="city">{{ $t('models.customer.city') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.city)" class="text-info text-sm">
                        {{ $t('models.customer.validations.city') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.city"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="phone"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.phone) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="phone"
                        v-model="useCustomers.state.form.body.phone"
                    >
                    <label for="phone">{{ $t('models.customer.phone') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.phone)" class="text-info text-sm">
                        {{ $t('models.customer.validations.phone') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.phone"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="mobile"
                        @keyup.enter="saveResource()"
                        placeholder="mobile"
                        v-model="useCustomers.state.form.body.mobile"
                    >
                    <label for="mobile">{{ $t('models.customer.mobile') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.mobile)" class="text-info text-sm">
                        {{ $t('models.customer.validations.mobile') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.mobile"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="email"
                        class="form-control rounded-4"
                        id="emailInput"
                        v-bind:class="checkEmailEntry(useCustomers.state.form.body.email)"
                        @keyup.enter="saveResource()"
                        placeholder="name@example.com"
                        v-model="useCustomers.state.form.body.email"
                    >
                    <label for="emailInput">{{ $t('models.customer.email') }}</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="!formUtilsComp.isEmailValid(useCustomers.state.form.body.email)" class="text-info text-sm">
                        {{ $t('models.customer.validations.email') }}
                    </div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.email"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <select
                        class="form-select rounded-4"
                        id="active"
                        v-bind:class="formUtilsComp.isFieldEmpty(useCustomers.state.form.body.active) ? 'is-invalid' : 'is-valid'"
                        v-model="useCustomers.state.form.body.active"
                        @keyup.enter="saveResource()"
                    >
                        <option value="">{{ $t('models.customer.validations.active') }}</option>
                        <option v-for="option in useCustomers.state.form.options.active" :value="option.value" v-bind:key="option.value">
                            {{ option.text }}
                        </option>

                    </select>
                    <label for="active">{{ $t('models.customer.active') }}</label>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useCustomers.useErrors.state.errors.active"></ValidationErrors>
                </div>

                <div class="mt-4 mb-3">
                    <label for="customerInfo" class="form-label mb-1 text-secondary">
                        {{ $t('models.customer.validations.info') }}
                    </label>

                    <QuillEditor
                        id="customerInfo"
                        theme="snow"
                        toolbar="essential"
                        v-model:content="useCustomers.state.form.body.info"
                        ref="myQuillEditorRef"
                        content="html"
                        contentType="html"
                        @focus="loseFocus()"
                        @blur="gainFocus()"
                    />
                </div>

                <button
                    @click="saveResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >
                    {{ $t('form.actions.save') }}
                </button>

            </template>

        <!--
            <template v-slot:footer>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"><strong>Submit</strong></button>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0" data-bs-dismiss="modal">Cancel</button>
            </template>
        -->
        </MyModal>

    </div>

</template>

<script>
// import Vue.js framework functions
import { ref } from 'vue'
import { onMounted } from 'vue'
import { reactive } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';

// import application's components
import MyModal from '@/components/MyModal.vue'
import TableHeaderItem from '@/components/table/TableHeaderItem.vue'
import NavItem from '@/components/navigation/NavItem.vue'

// import composables files
import useAuthComp from '@/composables/useAuthComp.js'
import useObjectsUtils from '@/composables/useObjectsUtils.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useModalsComp from '@/composables/useModalsComp.js'
import useCustomersComp from '@/composables/useCustomersComp.js'

// import informations and errors messages components
import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

// import WYSIWYG package
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
//import '@vueup/vue-quill/dist/vue-quill.bubble.css'

// import internationalization package
import { useI18n } from 'vue-i18n'


export default {

    name: 'customers',

    components: {
        MyModal,
        TableHeaderItem,
        NavItem,
        AltertMessage,
        ValidationErrors,
        ExceptionMessage,
        QuillEditor,
    },

    setup() {

        const { masterUser } = useAuthComp()

        const objUtils = useObjectsUtils()
        const useCustomers = useCustomersComp()
        const formUtilsComp = useFormUtilsComp()
        const useModals = useModalsComp()


        //const { body } = useCustomersComp()

        // Variable needed to access the reference of the QuillEditor
        // instance and use its methods
        const myQuillEditorRef = ref()
        const { t } = useI18n({ useScope: 'global' })


        const state = reactive({
            modal: {
                edit: 'editCustomerModal',
                show: 'showCustomerModal',
                alert: 'alertModal',
            /*
                header: '',
                action: '',
                componentEdit: null,
                componentShow: null,
                componentAlert: null
            */
            },
            customerToShow: {},
            customerInfo: '',
            form: {
                search: '',
            },
        });

        /**
         * Function called when component is mounted
         * @param  {[type]} state               [description]
         * @return {[type]}       [description]
         */
        onMounted(() => {

            // init data
            state.form.search = ''

            //useCustomers.getCustomers()
            //state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            //state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
            //state.modal.componentAlert = new Modal(document.getElementById(state.modal.alert))

            useModals.state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            useModals.state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
            useModals.state.modal.componentAlert = new Modal(document.getElementById(state.modal.alert))

            //useCustomers.sortCustomers('postcode', 'number')
            // Requested quantity for resources pagination
            useCustomers.state.pagination.meta.requestQtyPerPage = 10
            useCustomers.state.pagination.meta.requestedPage = 1

            //getAllCustomers()
            getCustomersPagination()
        })


        /**
         * Function called from seach input field. Take the entered name
         * and send a request to the backend to find the given customer
         * @return {[type]} [description]
         */
        function searchCustomers() {

            useCustomers.searchResources(state.form.search)
            .then(() => {
                useCustomers.sortCustomers('lastname', 'string')
                state.form.search = ''
            })
            .catch((error) => {
                //formUtilsComp.useSetErrors(error.response.data)
                useCustomers.useErrors.useSetErrors(error.response.data)
            })
        }

        /**
         * Set the amount of resources to display
         * @param {Number} nb  [Amount of resources to diaplay]
         */
        function setNbPerPageForPagination(nb) {
            useCustomers.state.pagination.meta.requestQtyPerPage = nb
            useCustomers.state.pagination.meta.requestedPage = 1
            getCustomersPagination()
        }

        /**
         * Get resources corresponding to the selected page number
         * @param  {Number} page    [Selected page number]
         * @return {[type]}      [description]
         */
        function getPageCustomersPagination(page) {
            useCustomers.state.pagination.meta.requestedPage = page
            getCustomersPagination()
        }

        /**
         * Get resources corresponding to the next page number
         * @return {Number} [Nest page number]
         */
        function getNextCustomersPagination() {
            if ( useCustomers.state.pagination.links.next !== null ) {
                useCustomers.state.pagination.meta.requestedPage = useCustomers.state.pagination.meta.curentPage + 1
                getCustomersPagination()
            }
        }

        /**
         * Get resources corresponding to the previous page number
         * @return {Number} [Previous page number]
         */
        function getPreviousCustomersPagination() {
            if ( useCustomers.state.pagination.links.prev !== null ) {
                useCustomers.state.pagination.meta.requestedPage = useCustomers.state.pagination.meta.curentPage - 1
                getCustomersPagination()
            }
        }

        /**
         * Called from the get...CustomersPagination functions.
         * Gets the resources from backend corresponding to selected page number
         * @return {Array} customers    [Array of resources returned from backend]
         */
        function getCustomersPagination() {

            useCustomers.getResourcesPaginate()
            .then(() => {
                useCustomers.sortCustomers('lastname', 'string')
            })
            .catch((error) => {
                //formUtilsComp.useSetErrors(error.response.data)
                useCustomers.useErrors.useSetErrors(error.response.data)
            })
        }

        /**
         * Gets all resources from backend, independently of pagination
         * @return {[type]} [description]
         */
        function getAllCustomers() {
            useCustomers.getCustomers()
            .then(() => {
                useCustomers.sortCustomers('lastname', 'string')
            })
            .catch((error) => {
                //formUtilsComp.useSetErrors(error.response.data)
                useCustomers.useErrors.useSetErrors(error.response.data)
            })
        }

        /**
         * Set modal attributes and open modal form to create a resource
         * @return {void} [void]
         */
        function createResource() {

            //useModals.setCreateModalAttributes( t('form.cards.customers.headers.create'), 'create' )
            useModals.setCreateModalAttributes( 'customers' )

            // Erase error messages
            //formUtilsComp.useResetErrors()
            useCustomers.useErrors.useResetErrors()

            // Flush and Init model's attributes
            useCustomers.flushAttributes()
            useCustomers.initAttributes()
        }

        /**
         * Set modal attributes and open modal form to edit a resource
         * @return {void} [void]
         */
        function editResource(resource) {

            useModals.setEditModalAttributes( 'customers' )

            // Erase error messages
            //formUtilsComp.useResetErrors()
            useCustomers.useErrors.useResetErrors()

            // Clone user's attributes
            useCustomers.state.form.body = objUtils.cloneObject(resource)

            // Set HTML content of QuillEditor component because it is only
            // loaded when component load
            console.log(myQuillEditorRef.value)
            myQuillEditorRef.value.setHTML(useCustomers.state.form.body.info)
        }



        /**
         * Toogle the display of modal form is close button clicked
         * @return {void} [void]
         */
        function closeShowResource() {
            // Close Modal component
            useModals.state.modal.componentShow.toggle()
        }


        /**
         * Set modal attributes and open modal form to show a resource
         * @return {void} [void]
         */
        function showResource(resource) {

            useModals.setShowModalAttributes( 'customers' )

            // Erase error messages
            //formUtilsComp.useResetErrors()
            useCustomers.useErrors.useResetErrors()

            // Clone user's attributes
            //useCustomers.state.form.body = objUtils.cloneObject(resource)

            state.customerToShow = {}
            state.customerInfo = ''

            state.customerToShow.id = resource.id
            state.customerToShow.civility = resource.civility
            state.customerToShow.lastname = resource.lastname
            state.customerToShow.firstname = resource.firstname
            state.customerToShow.street = resource.street
            state.customerToShow.number = resource.number
            state.customerToShow.postcode = resource.postcode
            state.customerToShow.city = resource.city
            state.customerToShow.phone = resource.phone
            state.customerToShow.mobile = resource.mobile
            state.customerToShow.email = resource.email
            state.customerToShow.active = resource.active

            state.customerInfo = resource.info

        }




        /**
         * Save the new created or the edited existing resource to the backend.
         * If all works fine, the modal form is flushed and closed.
         * If errors occures from backend, the error messages will be displayed
         * in the form to indicate the user what to do.
         * @return {Object} [Object Collection containing the resource attributes]
         */
        function saveResource() {

            if ( useCustomers.state.form.body.id == null ) {

                useCustomers.storeResource()
                .then(() => {
                    useCustomers.state.form.response.alert = 'alert-success'
                    // Close Modal component
                    useModals.state.modal.componentEdit.toggle()
                    myQuillEditorRef.value.setHTML('')

                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    useCustomers.state.form.response.alert = 'alert-danger'
                    //formUtilsComp.useSetErrors(error.response.data)
                    useCustomers.useErrors.useSetErrors(error.response.data)
                })
            /*
                .then(() => {
                    useModals.displayAlertComponent()
                })
            */
            } else {
                useCustomers.updateResource()
                .then(() => {
                    useCustomers.state.form.response.alert = 'alert-success'
                    // Close Modal component
                    useModals.state.modal.componentEdit.toggle()

                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    useCustomers.state.form.response.alert = 'alert-danger'
                    //formUtilsComp.useSetErrors(error.response.data)
                    useCustomers.useErrors.useSetErrors(error.response.data)
                })
            /*
                .then(() => {
                    useModals.displayAlertComponent()
                })
            */
            }
        }

        /**
         * Open confirmation message if current resource has to be deleted inspect
         * backend. If yes, delete request with the resource ID is sent to the
         * backend.
         * @param  {Object} resource [the desired resource to delete]
         * @return {void}    [void]
         */
        function deleteResource(resource) {

            if ( confirm(t('form.infos.delete.confirmation') + ` '${resource.lastname} ${resource.firstname}'`) ) {
                useCustomers.deleteResource(resource)
                .then(() =>{
                    //useModals.setDeleteModalAttributes( 'customers' )
                    useModals.displayAlertComponent()
                })
                .catch((error) => {
                    useCustomers.state.form.response.alert = 'alert-danger'
                    //formUtilsComp.useSetErrors(error.response.data)
                    useCustomers.useErrors.useSetErrors(error.response.data)
                })
            /*
                .then(() => {
                    useModals.displayAlertComponent()
                })
            */
            }
        }

        /**
         * Send the toggle request on status of the resource to the backend
         * between "active" or "inactive".
         * @param  {Object} resource   [The desired resource to toggle status]
         * @return {void}          [void]
         */
        function toggleActive(resource) {

            useCustomers.state.form.status = 'toggeling'

            // Clone user's attributes
            useCustomers.state.form.body = objUtils.cloneObject(resource)

            //let id = resource.id

            if (resource.active == 1) {
                //resource.active = 0
                useCustomers.state.form.body.active = 0
            } else {
                //resource.active = 1
                useCustomers.state.form.body.active = 1
            }

            useCustomers.updateResource()
            .then(() => {
                useCustomers.state.form.response.alert = 'alert-success'

                //displayAlertComponent()
                useModals.displayAlertComponent()
            })
            .catch(() => {
                //formUtilsComp.useSetErrors(error.response.data)
                useCustomers.state.form.response.alert = 'alert-danger'
                //useCustomers.useErrors.useSetErrors(error.response.data)
            })
        /*
            .then(() => {

                //displayAlertComponent()
                useModals.displayAlertComponent()

                // Open Modal component
                state.modal.componentAlert.toggle()
                // Reset message after a few milliseconds
                setTimeout(() => {
                    state.modal.componentAlert.toggle()
                }, 2000)

            })
        */

        }


        /**
         * Test if entered email string is valid.
         * If email string empty, no validation returned
         *
         * @param  {String} email  [email String]
         * @return {String}  [CSS class String]
         */
        function checkEmailEntry(email) {

            if (email === "") {
                return null
            } else {
                return formUtilsComp.isEmailValid(useCustomers.state.form.body.email) ? 'is-valid' : 'is-invalid'
            }
        }


        /**
         * Test function to log when focus lost on input form
         * @return {void} [void]
         */
        function loseFocus() {
            console.log("loses focus")
        }

        /**
         * Test function to log when focus on input form
         * @return {void} [void]
         */
        function gainFocus() {
            console.log("gains focus")
        }


        /**
         * All returned data to be available in the template "html" part
         */
        return {
            state,
            useCustomers,
            useModals,
            formUtilsComp,
            getAllCustomers,
            createResource,
            editResource,
            showResource,
            closeShowResource,
            saveResource,
            deleteResource,
            toggleActive,
            checkEmailEntry,
            myQuillEditorRef,
            masterUser,
            loseFocus,
            gainFocus,
            getNextCustomersPagination,
            getPreviousCustomersPagination,
            setNbPerPageForPagination,
            getPageCustomersPagination,
            searchCustomers,
        }

    },
}

</script>

<style>
    @import '../../assets/css/logo.css';
</style>
